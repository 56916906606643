import React, { ReactElement } from "react";

export default function FairCompensationsIcon(): ReactElement {
    return (
        <svg
            width="108"
            height="80"
            viewBox="0 0 108 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M104.242 62.0205L51.4283 35.2866L47.8583 41.4639L97.4195 73.84C98.197 74.3347 99.0666 74.667 99.9759 74.8167C100.885 74.9664 101.815 74.9304 102.71 74.711C103.605 74.4916 104.447 74.0932 105.184 73.5399C105.921 72.9866 106.538 72.2899 106.998 71.4916V71.4916C107.46 70.6934 107.754 69.81 107.865 68.8948C107.975 67.9796 107.899 67.0515 107.641 66.1665C107.383 65.2814 106.949 64.4578 106.364 63.7453C105.779 63.0327 105.056 62.446 104.238 62.0205H104.242Z"
                fill="white"
            />
            <path
                d="M15.2296 29.6898C14.8902 30.2777 14.7983 30.9763 14.9739 31.6321C15.1496 32.2878 15.5785 32.8469 16.1664 33.1864L39.0886 46.4195C39.3796 46.5876 39.7008 46.6968 40.0339 46.7407C40.3671 46.7847 40.7056 46.7626 41.0302 46.6757C41.3548 46.5888 41.6591 46.4388 41.9257 46.2343C42.1923 46.0298 42.4161 45.7748 42.5842 45.4839C42.7522 45.1929 42.8614 44.8717 42.9053 44.5386C42.9493 44.2055 42.9272 43.8669 42.8403 43.5423C42.7534 43.2177 42.6034 42.9135 42.3989 42.6468C42.1944 42.3802 41.9394 42.1564 41.6485 41.9883H41.6593L48.782 29.6444C49.369 29.9599 50.0559 30.0341 50.6967 29.8511C51.3375 29.6682 51.8816 29.2425 52.2136 28.6647C52.5455 28.0868 52.639 27.4023 52.4742 26.7566C52.3094 26.1109 51.8993 25.5549 51.3311 25.2068L28.411 11.9758C27.8252 11.6557 27.1375 11.577 26.4946 11.7566C25.8516 11.9363 25.3043 12.36 24.9692 12.9374C24.6342 13.5149 24.538 14.2003 24.7011 14.8476C24.8643 15.495 25.2738 16.053 25.8425 16.4027L18.7198 28.7466C18.4288 28.5786 18.1076 28.4695 17.7746 28.4257C17.4415 28.3818 17.103 28.404 16.7785 28.491C16.454 28.578 16.1498 28.7281 15.8833 28.9326C15.6168 29.1372 15.3932 29.3923 15.2253 29.6833L15.2296 29.6898Z"
                fill="white"
            />
            <path
                d="M32.7256 8.55694L52.1383 19.7655C53.1903 20.3511 54.4303 20.4996 55.5907 20.1789C56.7512 19.8582 57.739 19.0941 58.3411 18.0515C58.9431 17.0089 59.1111 15.7715 58.8087 14.6061C58.5064 13.4407 57.7579 12.441 56.7249 11.8226L37.3101 0.614006C36.7886 0.312987 36.2128 0.117673 35.6158 0.0391523C35.0188 -0.0393685 34.4121 0.000467916 33.8305 0.156399C33.2488 0.312331 32.7036 0.581292 32.2259 0.947937C31.7482 1.31458 31.3474 1.77171 31.0464 2.29325C30.7454 2.81479 30.55 3.39053 30.4715 3.98756C30.393 4.5846 30.4328 5.19125 30.5888 5.77288C30.7447 6.35452 31.0137 6.89977 31.3803 7.37746C31.747 7.85515 32.2041 8.25592 32.7256 8.55694V8.55694Z"
                fill="white"
            />
            <path
                d="M10.777 46.5729L30.1896 57.7815C31.2429 58.3897 32.4947 58.5546 33.6696 58.2399C34.8444 57.9252 35.8462 57.1566 36.4544 56.1033C37.0626 55.05 37.2275 53.7983 36.9128 52.6234C36.5981 51.4485 35.8295 50.4468 34.7762 49.8386L15.3614 38.63C14.84 38.329 14.2644 38.1336 13.6675 38.055C13.0706 37.9765 12.4641 38.0162 11.8825 38.172C11.301 38.3278 10.7559 38.5967 10.2782 38.9632C9.80059 39.3297 9.39982 39.7867 9.0988 40.3081C8.79778 40.8295 8.60241 41.4051 8.52383 42.002C8.44526 42.5989 8.48502 43.2054 8.64085 43.787C8.79668 44.3685 9.06553 44.9137 9.43204 45.3913C9.79856 45.869 10.2556 46.2697 10.777 46.5708V46.5729Z"
                fill="white"
            />
            <path
                d="M53.496 74.9061V67.2633C53.496 66.9286 53.43 66.5972 53.3019 66.2881C53.1738 65.9789 52.986 65.6981 52.7493 65.4616C52.5125 65.225 52.2315 65.0375 51.9222 64.9096C51.613 64.7818 51.2816 64.7161 50.9469 64.7163H5.09383C4.75936 64.7163 4.42817 64.7822 4.11917 64.9102C3.81016 65.0382 3.52939 65.2258 3.29289 65.4623C3.05639 65.6989 2.86878 65.9796 2.74079 66.2886C2.61279 66.5976 2.54691 66.9288 2.54691 67.2633V74.9061C1.87143 74.9061 1.22361 75.1745 0.745974 75.6522C0.268335 76.1298 0 76.7776 0 77.4531C0 78.1286 0.268335 78.7764 0.745974 79.254C1.22361 79.7317 1.87143 80 2.54691 80H53.496C54.1715 80 54.8193 79.7317 55.2969 79.254C55.7746 78.7764 56.0429 78.1286 56.0429 77.4531C56.0429 76.7776 55.7746 76.1298 55.2969 75.6522C54.8193 75.1745 54.1715 74.9061 53.496 74.9061Z"
                fill="#EA7969"
            />
        </svg>
    );
}
