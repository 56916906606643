import React, { ReactElement } from "react";

export default function HelpIcon(): ReactElement {
    return (
        <svg
            width="108"
            height="80"
            viewBox="0 0 108 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M69.994 16.2557C69.994 25.2338 62.7165 32.5114 53.7384 32.5114C44.7602 32.5114 37.4827 25.2338 37.4827 16.2557C37.4827 7.27753 44.7608 0 53.7384 0C62.7159 0 69.994 7.27813 69.994 16.2557Z"
                fill="white"
            />
            <path
                d="M51.5372 60.4529L60.5296 37.0242C60.5401 36.9979 60.544 36.9694 60.5409 36.9412C60.5378 36.913 60.5279 36.886 60.5119 36.8626C60.496 36.8391 60.4745 36.82 60.4495 36.8067C60.4244 36.7935 60.3965 36.7866 60.3681 36.7867H46.8041C46.7809 36.7867 46.758 36.7913 46.7367 36.8003C46.7154 36.8094 46.6961 36.8226 46.68 36.8392C46.6639 36.8558 46.6512 36.8755 46.6429 36.8971C46.6345 36.9187 46.6306 36.9417 46.6313 36.9648L47.4929 60.3561C47.4957 60.431 47.5274 60.5018 47.5813 60.5538C47.6352 60.6057 47.7072 60.6347 47.7821 60.6346H51.267C51.3255 60.6345 51.3825 60.6168 51.4307 60.5837C51.4789 60.5507 51.5161 60.5039 51.5372 60.4494V60.4529Z"
                fill="white"
            />
            <path
                d="M66.9426 37.523C66.9109 37.5151 66.8777 37.5161 66.8465 37.5258C66.8153 37.5355 66.7874 37.5537 66.7658 37.5782C66.7443 37.6027 66.7299 37.6327 66.7242 37.6648C66.7185 37.697 66.7217 37.7301 66.7336 37.7605L75.5769 59.6032C78.0822 64.6706 75.2165 70.7505 69.7132 72.0426L69.6479 72.058C65.6929 72.9792 61.6513 73.4784 57.5909 73.5473L57.5624 73.5787H38.928C37.7941 73.5787 36.7067 73.1283 35.9049 72.3265C35.1031 71.5247 34.6527 70.4373 34.6527 69.3034V64.9182C34.6527 63.7844 35.1031 62.6969 35.9049 61.8951C36.7067 61.0934 37.7941 60.6429 38.928 60.6429H42.3548C42.3953 60.643 42.4353 60.6345 42.4722 60.6181C42.5092 60.6017 42.5424 60.5778 42.5695 60.5478C42.5967 60.5178 42.6172 60.4824 42.6298 60.444C42.6424 60.4056 42.6468 60.3649 42.6428 60.3247L40.3602 37.7973C40.3573 37.7716 40.3489 37.7469 40.3355 37.7248C40.322 37.7027 40.304 37.6838 40.2825 37.6694C40.261 37.6549 40.2367 37.6454 40.2111 37.6413C40.1856 37.6373 40.1595 37.6389 40.1346 37.6459C33.192 39.764 26.4839 45.5891 27.0314 52.1612L29.1132 79.2239C29.1295 79.4351 29.2249 79.6324 29.3804 79.7762C29.5358 79.9201 29.7398 80 29.9516 80H77.5276C77.7394 80 77.9434 79.9201 78.0988 79.7762C78.2543 79.6324 78.3497 79.4351 78.366 79.2239L80.4479 52.1612C81.006 45.4626 74.0271 39.5401 66.9438 37.5277L66.9426 37.523Z"
                fill="white"
            />
            <path
                d="M50.2582 62.3495H41.0716C39.8221 62.3495 38.6237 62.8458 37.7401 63.7293C36.8565 64.6128 36.36 65.8111 36.3599 67.0607V67.1563C36.3598 67.7751 36.4816 68.3878 36.7183 68.9596C36.9551 69.5313 37.3021 70.0508 37.7397 70.4884C38.1772 70.9259 38.6967 71.2731 39.2683 71.5099C39.84 71.7467 40.4528 71.8686 41.0716 71.8686H46.8955C46.9721 71.8686 47.0468 71.8453 47.1098 71.802C47.1729 71.7586 47.2214 71.6972 47.2488 71.6258L50.6115 62.8643C50.6334 62.807 50.6411 62.7452 50.6339 62.6843C50.6267 62.6234 50.6047 62.5651 50.5699 62.5146C50.5352 62.464 50.4886 62.4227 50.4343 62.3941C50.38 62.3656 50.3195 62.3507 50.2582 62.3507V62.3495Z"
                fill="#EA7969"
            />
        </svg>
    );
}
