import React, { ReactElement } from "react";

import FreeIcon from "../icons/freeIcon";
import NeverStopFightingIcon from "../icons/neverStopFightingIcon";
import PersonalInjuryIcon from "../icons/personalInjuryIcon";
import NoObligationIcon from "../icons/noObligationIcon";
import FairCompensationsIcon from "../icons/fairCompensationsIcon";
import HelpIcon from "../icons/helpIcon";
import FeatureList from "@components/shared/featureList";
import { useDomainContext } from "@hooks/useDomainContext";

const whyUsList = [
    {
        title: "It’s Free if We Don’t Win",
        icon: <FreeIcon />,
    },
    {
        title: "We’ll Never Stop Fighting for You",
        icon: <NeverStopFightingIcon />,
    },
    {
        title: "Personal Injury Law Made Easy",
        icon: <PersonalInjuryIcon />,
    },
    {
        title: "Free, No-Obligation Consultation",
        icon: <NoObligationIcon />,
    },
    {
        title: "Get Maximum & Fair Compensation",
        icon: <FairCompensationsIcon />,
    },
    {
        title: "We Can Help with Any Injury Case",
        icon: <HelpIcon />,
    },
];

export default function WhyUs(): ReactElement {
    const { domain } = useDomainContext();
    return (
        <FeatureList
            featureList={whyUsList}
            mainTitle={
                <>
                    Why{" "}
                    <span style={{ color: "#ea7969" }}>
                        {domain.niceName ?? "InjuryCompensation.com"}
                    </span>
                </>
            }
            classes={{
                mainTitleClasses: "text-center text-2xl lg:text-4xl mb-14",
                mainSectionClasses:
                    "max-w-7xl mx-auto block px-4vw lg:px-4 py-[72px]",
                innerSectionClasses: "lg:flex flex-wrap justify-center",
                oneBoxClasses: "text-center mb-12 lg:mb-20 w-full lg:w-1/3",
                iconClasses: "children:mx-auto",
                titleClasses:
                    "font-medium text-lg lg:text-xl mt-6 font-light mx-auto w-full max-w-[220px]",
            }}
            colors={{
                mainTitleColor: "white",
                bgColor: "#444b53",
                titleColor: "white",
            }}
        />
    );
}
