import React, { ReactElement } from "react";

export default function PersonalInjuryIcon(): ReactElement {
    return (
        <svg
            width="108"
            height="80"
            viewBox="0 0 108 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M45.8996 74.6273C45.0901 75.127 44.1154 75.2848 43.1897 75.0658C42.264 74.8469 41.4631 74.2692 40.9633 73.4599C40.7153 73.0593 40.5486 72.6138 40.4727 72.1489C40.3969 71.6839 40.4134 71.2085 40.5213 70.75C40.6292 70.2914 40.8264 69.8585 41.1016 69.4761C41.3768 69.0938 41.7247 68.7693 42.1253 68.5214C42.5261 68.2739 42.9718 68.1078 43.4368 68.0326C43.9018 67.9573 44.3772 67.9744 44.8356 68.0829C45.294 68.1913 45.7266 68.389 46.1086 68.6647C46.4906 68.9403 46.8146 69.2885 47.0621 69.6894C47.31 70.09 47.4766 70.5354 47.5523 71.0004C47.6281 71.4653 47.6115 71.9406 47.5035 72.3991C47.3956 72.8577 47.1984 73.2905 46.9232 73.6728C46.648 74.0551 46.3001 74.3794 45.8996 74.6273ZM36.955 49.0807C36.9434 49.0945 36.9341 49.1093 36.9236 49.1219C35.8798 48.3253 34.3514 48.1935 33.2295 48.8868L23.1911 55.0949C21.9072 55.8888 21.6215 57.4727 22.5286 58.672L38.7191 78.6697C38.7546 78.7272 38.7938 78.7822 38.8366 78.8345C39.8294 80.0432 41.711 80.3646 43.0323 79.5487L53.0537 73.3544C54.3129 72.5759 54.5969 71.0415 53.7536 69.8509L36.955 49.084V49.0807Z"
                fill="white"
            />
            <path
                d="M51.4226 34.4445L51.2946 34.2435C51.0111 33.7985 50.6754 33.0392 50.2508 32.0783C49.3717 30.0967 47.3862 26.1092 45.6562 23.8727C43.7685 21.4306 40.7727 18.5271 38.3049 17.7536C36.9753 17.3344 35.7859 17.3844 35.0212 17.7063C34.413 17.9618 33.9746 18.181 33.724 18.3502C32.0396 19.4863 31.0051 21.8421 31.4974 23.454C31.6622 24.0034 32.1978 24.5094 32.7137 24.9989C32.8379 25.1165 32.9659 25.2379 33.0851 25.3566C33.9229 26.1971 36.4666 29.9808 37.2055 31.8514C38.4691 35.0307 39.3218 45.496 38.7636 47.7837L55.3919 68.3138C55.8946 68.5792 56.905 68.5369 57.3467 68.4742C60.1046 68.0853 63.0197 67.22 65.8781 65.4526L77.384 58.3402C78.0476 57.9298 80.4117 55.8641 79.8535 53.2496C79.5898 52.0277 78.5998 50.8702 77.7356 50.2664L77.7004 50.2411L77.6675 50.1933C77.6125 50.1164 77.5702 50.0516 77.5405 50.0076L77.5351 49.9988C77.532 49.9943 77.5287 49.9899 77.5252 49.9857C77.5164 49.9736 77.5076 49.9609 77.4983 49.9466L77.3933 49.7879L77.5246 49.6489C78.608 48.4996 78.8833 46.8849 78.2586 45.3263C77.5274 43.5056 76.1863 42.4145 75.2589 42.03L74.9881 41.9201L75.1491 41.6756C76.1704 40.123 75.9951 38.137 74.6832 36.3641C73.7289 35.0774 72.2576 34.2023 70.7474 34.0232L70.7188 34.0199L83.9689 25.5664C85.0657 24.8885 85.8484 23.8028 86.145 22.5481C86.4416 21.2933 86.2278 19.9721 85.5506 18.8749C85.2149 18.3314 84.7753 17.8594 84.2571 17.4858C83.7389 17.1122 83.1522 16.8443 82.5304 16.6975C81.9087 16.5507 81.2641 16.5278 80.6335 16.6301C80.0029 16.7324 79.3987 16.9579 78.8552 17.2937L51.4226 34.4445Z"
                fill="white"
            />
            <path
                d="M58.3191 0C57.8649 0.000436363 57.4294 0.181055 57.1083 0.502209C56.7871 0.823364 56.6065 1.25882 56.6061 1.713V12.6019C56.6067 13.056 56.7873 13.4914 57.1084 13.8125C57.4296 14.1336 57.8649 14.3143 58.3191 14.3149C58.7733 14.3144 59.2087 14.1338 59.5299 13.8127C59.851 13.4915 60.0316 13.0561 60.0321 12.6019V1.713C60.0316 1.25882 59.851 0.823364 59.5299 0.502209C59.2087 0.181055 58.7733 0.000436363 58.3191 0V0Z"
                fill="#EA7969"
            />
            <path
                d="M71.5412 2.7969C71.3535 2.72837 71.1553 2.69323 70.9555 2.69307C70.6041 2.69399 70.2614 2.8026 69.9736 3.00426C69.6858 3.20593 69.4667 3.49096 69.3458 3.82096L65.61 13.9742C65.4544 14.4014 65.4749 14.8728 65.6668 15.2849C65.8587 15.697 66.2064 16.016 66.6335 16.1718C66.8211 16.2405 67.0193 16.2756 67.2191 16.2756C67.5706 16.275 67.9133 16.1665 68.2012 15.9649C68.489 15.7633 68.7081 15.4783 68.8289 15.1483L72.5647 4.99501C72.6425 4.78389 72.6777 4.55945 72.6683 4.33464C72.6589 4.10984 72.605 3.88913 72.5098 3.68527C72.4152 3.48098 72.281 3.29748 72.115 3.14537C71.9491 2.99327 71.7546 2.87557 71.5428 2.7991L71.5412 2.7969Z"
                fill="#EA7969"
            />
            <path
                d="M45.6858 2.69313C45.486 2.69329 45.2878 2.72843 45.1001 2.79696C44.6731 2.95277 44.3253 3.27174 44.1333 3.6838C43.9413 4.09586 43.9207 4.5673 44.0761 4.99452L47.8119 15.1478C47.9327 15.4778 48.1518 15.7628 48.4397 15.9644C48.7275 16.1659 49.0702 16.2744 49.4216 16.2751C49.6214 16.2752 49.8197 16.2401 50.0073 16.1713C50.4344 16.0156 50.7823 15.6966 50.9743 15.2845C51.1664 14.8724 51.1869 14.4009 51.0313 13.9737L47.2955 3.82048C47.1747 3.49043 46.9556 3.20535 46.6678 3.00367C46.38 2.802 46.0372 2.69342 45.6858 2.69258V2.69313Z"
                fill="#EA7969"
            />
        </svg>
    );
}
